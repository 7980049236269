











































import { Component, Vue } from "vue-property-decorator";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";

@Component({
  components: {
    Hint,
    FscSimpleCard,
    FscPageHeader,
  },
})
export default class Pulse extends Vue {
  public name = "Pulse";
}
