











import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const MainTabsModule = namespace("main-tabs");
@Component
export default class FscPageHeader extends Vue {
  public name = "FscPageHeader";

  @MainTabsModule.Getter("getCustomLabel")
  private customLabel: any;

  @MainTabsModule.Getter("getActive")
  private activeTab: any;

  @Prop({ default: () => false, type: Boolean })
  public removeSpacing!: any;

  public get title(): string {
    return this.activeTab ? this.activeTab.label : "";
  }
  //  public updated() {
  //    return this.title;
  // }
}
