
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FscSimpleCard extends Vue {
  public name = "FscSimpleCard";

  @Prop({ type: String, default: () => "" })
  public contentClass!: string;

  @Prop({ type: String, default: () => "" })
  public headerClass!: string;

  @Prop({ type: String, default: () => "" })
  public footerClass!: string;

  @Prop({ type: Boolean, default: () => true })
  public defaultPadding!: boolean;

  @Prop({ type: String, default: () => "py-3" })
  public padding!: string;

  public get hasHeaderSlot(): boolean {
    return !!this.$slots.header;
  }

  public get hasFooterSlot(): boolean {
    return !!this.$slots.footer;
  }
}
